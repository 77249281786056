import React from "react";
import { useLocation } from "react-router-dom";
import './page-container.scss'

const Container = ({ children }) => {
  const pageContainerStyle =
    useLocation().pathname === "/" ? {} : { marginTop: "80px" };

  return (
    <div className="page-container" style={pageContainerStyle}>
      {children}
    </div>
  );
};

export default Container;
