import { FetchResponse, fetchApi } from "utils/apiUtils";

const BACKEND_BASE_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/send_message/`;

export const sendMessage = (formData: Record<string, any>): Promise<FetchResponse<any>> => {
  return fetchApi(BACKEND_BASE_URL, {
    method: "POST",
    body: JSON.stringify(formData),
  });
};
