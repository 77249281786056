import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

interface InfoCardProps {
    children: React.ReactNode;
}

const InfoCard: React.FC<InfoCardProps> = ({ children }) => {
  return (
    <Container className="my-2">
      <Card>
        <Card.Body>
          {children}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default InfoCard;
