import { FetchResponse, fetchApi } from "utils/apiUtils";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
const API_URL = `${BACKEND_BASE_URL}/api/newsletter_subscribe/`;

export const subscribeToNewsletter = (formData: Record<string, any>): Promise<FetchResponse<any>> => {
  return fetchApi(API_URL, {
    method: "POST",
    body: JSON.stringify(formData),
  });
};
