import colorTheme from 'constants/colorTheme';
import React from 'react';

interface ListProps {
  items: string[];
  nestedItems?: string[][];
}

const List: React.FC<ListProps> = ({ items, nestedItems }) => (
  <ul className="list-unstyled">
    {items.map((item, index) => {
      const parts = item.split(':');
      const boldText = parts[0];
      const normalText = parts.length > 1 ? parts.slice(1).join(':') : '';
      
      return (
        <li key={index} className="mb-2">
          <span className="mr-3">•</span>
          <strong className="text-primary mr-2">{boldText}{normalText && ':'}</strong>{normalText}
          {nestedItems && nestedItems[index] && (
            <ul className="ml-4">
              {nestedItems[index].map((nestedItem, nestedIndex) => (
                <li key={nestedIndex}>
                  <span className="mr-3">•</span>
                  {nestedItem}
                </li>
              ))}
            </ul>
          )}
        </li>
      );
    })}
  </ul>
);

export default List;
