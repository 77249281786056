import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/loading.json';

interface LoadingSpinnerProps {
  size?: number;
  centerOfPage?: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = 150, centerOfPage = true }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={centerOfPage? "d-flex justify-content-center align-items-center" : ""}
      style={centerOfPage? { 
        height: '100vh', 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100vw',
      }: {}}>
      <Lottie options={defaultOptions} height={size} width={size} />
      </div>
  );
};

export default LoadingSpinner;
