import { FetchResponse, fetchApi } from "utils/apiUtils";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
const API_URL = `${BACKEND_BASE_URL}/api/multi_search/`;

export const fetchSearchResults = async (query: string): Promise<FetchResponse<any>> => {
  if (typeof query !== "string" || query.trim() === "") {
    throw new Error("Invalid query");
  }

  const encodedQuery = encodeURIComponent(query);
  return await fetchApi(`${API_URL}?query=${encodedQuery}`, { method: "GET" });
};
