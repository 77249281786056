import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { validateFields } from "utils/inputUtils";

import {
  CONTACT_FORM_FIELDS as FIELDS,
  CONTACT_FORM_VALIDATION_RULES as RULES,
} from "constants/inputConstants";
import SuccessMessage from "./SuccessMessage";
import { sendMessage } from "api/contactService";
import { FaPaperPlane } from "react-icons/fa";

const ContactForm: React.FC = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateFields(RULES, setErrors, t, formData)) return;

    setIsLoading(true);

    try {
      await sendMessage(formData);
      setIsSuccess(true);
    } catch (error) {
      if (error instanceof Error) alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isSuccess)
    return (
      <Container className="py-5 d-flex justify-content-center">
        <SuccessMessage
          titleKey="contacts.form.success.title"
          contentKey="contacts.form.success.description"
        />
      </Container>
    );

  return (
    <Container className="py-5 d-flex justify-content-center">
      <Form onSubmit={handleSubmit}>
        <h5 className="title text-center">{t("contacts.form.title")}</h5>
        <p className="description text-center">
          {t("contacts.form.description")}
        </p>
        {FIELDS.map((field, index) => (
          <Form.Group
            className={`mb-3 ${errors[field.name] ? "has-error" : ""}`}
            key={index}
          >
            <Form.Control
              name={field.name}
              as={field.type === "textarea" ? "textarea" : "input"}
              className={`${field.className} ${
                errors[field.name] ? "is-invalid" : ""
              }`}
              placeholder={t(field.placeholder)}
              onChange={handleInputChange}
            />
            {errors[field.name] && (
              <div className="invalid-feedback">{errors[field.name]}</div>
            )}
          </Form.Group>
        ))}
        <div className="text-center">
          <Button type="submit" disabled={isLoading} className="gap-2">
            {isLoading ? t("loading") : t("input.submit")}
            <FaPaperPlane />
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default ContactForm;
