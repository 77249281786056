import { FetchResponse, fetchApi } from "utils/apiUtils";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
const CREATE_ORDER_URL = `${BACKEND_BASE_URL}/api/order/create/`;
const GET_ORDER_ID_URL = `${BACKEND_BASE_URL}/api/order/get_order_id/`;


export const createOrder = async (data: any): Promise<void> => {
  try {
    const response: FetchResponse<{ order_id: number }> = await fetchApi<{ order_id: number }>(CREATE_ORDER_URL, {
      method: "POST",
      body: JSON.stringify(data),
    });

    if (response.status !== "success" || !response.data) {
      throw new Error(`Failed to create order. ${response.message}`);
    }
  } catch (error) {
    console.error("An error occurred while creating the order:", error);
    throw new Error("An error occurred while creating the order.");
  }
};


export const getOrderId = async (): Promise<number | null> => {
  try {
    const response: FetchResponse<{ order_id: number }> = await fetchApi<{ order_id: number }>(GET_ORDER_ID_URL, {
      method: "GET",
    });

    if (response.status === "success" && response.data) {
      return response.data.order_id;
    } else {
      console.error(`Failed to fetch order ID: ${response.message}`);
      return null;
    }
  } catch (err) {
    alert("There was an error fetching the order");
    return null;
  }
};