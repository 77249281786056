import React, { useState, useEffect } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_OPTIONS } from '../../constants/languageConstants';
import 'bootstrap/dist/css/bootstrap.min.css';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  const userLocale = navigator.language;
  const defaultLanguage = userLocale.startsWith('it') ? 'it' : 'en';

  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    localStorage.getItem('language') || defaultLanguage
  );
  const [toggleContents, setToggleContents] = useState<React.ReactNode>('Select Language');
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const selectedOption = LANGUAGE_OPTIONS.find(({ value }) => value === selectedLanguage);
    if (selectedOption) {
      setToggleContents(
        <>
          <img src={selectedOption.flag} alt={selectedOption.label} className="rounded-circle" style={{ width: 18, height: 18 }} />
        </>
      );
    }
    localStorage.setItem('language', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      setSelectedLanguage(eventKey);
    }
  };

  return (
    <Form>
      <Dropdown 
        onSelect={handleSelect} 
        show={showMenu}
        onMouseEnter={() => setShowMenu(true)}
        onMouseLeave={() => setShowMenu(false)}
      >
        <Dropdown.Toggle
          as="div"
          id="dropdown-flags"
          className="text-center"
        >
          {toggleContents}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {LANGUAGE_OPTIONS.map(({ value, label, flag }) => (
            <Dropdown.Item key={value} eventKey={value} className="text-center">
              <img src={flag} alt={label} className="rounded-circle" style={{ width: 20, height: 20 }} /> {label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Form>
  );
};

export default LanguageSelector;
