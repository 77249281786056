import React from "react";
import Contacts from "../components/Contacts/Contacts";
import { Container } from "react-bootstrap";
import Page from "./Page";

const ContactsPage: React.FC = () => {
  return (
    <Page>
      <Container className="p-5">
        <Contacts />
      </Container>
    </Page>
  );
};

export default ContactsPage;
