import LanguageSelector from "components/LanguageSelector/LanguageSelector";
import SocialIcons from "components/Shared/Icons/SocialIcons";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./TopBar.scss";

const TopBar = () => {
  const { t } = useTranslation();

  return (
    <div className="text-light topbar">
      <Row className="mx-2 align-items-center small-text">
        <Col xs={4}>
          <SocialIcons variant="light" size="small" />
        </Col>
        <Col xs={4} className="text-center">
          {t("delivery.description").toUpperCase()}
        </Col>
        <Col xs={4} className="text-right d-flex justify-content-end align-items-center gap-3">
          <LanguageSelector/>
        </Col>
      </Row>
    </div>
  );
};

export default TopBar;
