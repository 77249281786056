import React, { useState, useCallback } from "react";
import {
  Form,
  FormControl,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SEARCHBAR_VALIDATION_RULES } from "constants/inputConstants";
import { sanitizeInput, validateFields } from "utils/inputUtils";
import { useNavigate } from "react-router-dom";
import { useSearch } from "./SearchContext";
import { FaSearch } from "react-icons/fa";

const SearchBar = ({ toggleModal }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [isValidQuery, setIsValidQuery] = useState(true);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const navigate = useNavigate();
  const { setQuery } = useSearch();

  const validateSearchQuery = useCallback(
    (query: string) =>
      validateFields(SEARCHBAR_VALIDATION_RULES, setErrors, t, {
        search: query,
      }),
    [t]
  );

  const handleInputChange = useCallback((e) => {
    const sanitizedValue = sanitizeInput(e.target.value);
    setSearchText(sanitizedValue);
  }, []);

  const executeSearch = useCallback(() => {
    setIsValidQuery(validateSearchQuery(searchText));
    if (validateSearchQuery(searchText)) {
      navigate("/risultatiRicerca");
      setQuery(searchText);
      toggleModal();
    }
  }, [validateSearchQuery, searchText, navigate, setQuery, toggleModal]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        executeSearch();
      }}
    >
      <InputGroup className="mb-3">
        <FormControl
          isInvalid={!isValidQuery}
          type="text"
          placeholder={t("input.search.placeholder")}
          value={sanitizeInput(searchText)}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              executeSearch();
            }
          }}
          style={{ height: "50px", borderRadius: "15px 0px 0px 15px" }}
        />
        <Button variant="light border" onClick={executeSearch} style={{ height: "50px", borderRadius: "0px 15px 15px 0px" }}>
          <FaSearch onClick={executeSearch} role="button"/>
        </Button>
      </InputGroup>
      {!isValidQuery && (
        <Form.Control.Feedback type="invalid">
          {errors.search}
        </Form.Control.Feedback>
      )}
    </Form>
  );
};

export default SearchBar;
