import React from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import IconButton from "./IconButton";

interface Props {
  variant?: "light" | "dark";
  size?: "medium" | "small";
}

interface ISocialLinks {
  facebook: string;
  twitter: string;
  instagram: string;
}

const socialLinks: ISocialLinks = {
  twitter: "https://twitter.com/saidtappeti",
  facebook:
    "https://www.facebook.com/Saidtappeti-tappeti-persiani-ed-orientali-115546768604430/",
  instagram: "https://www.instagram.com/said_tappeti",
};

const MEDIUM_SIZE = 25;
const SMALL_SIZE = 18;

const SocialIcons: React.FC<Props> = ({ variant = "dark", size = "medium" }) => {
  return (
    <div>
      <IconButton
        icon={
          <FaFacebook size={size === "medium" ? MEDIUM_SIZE : SMALL_SIZE} />
        }
        link={socialLinks.facebook}
        variant={variant}
      />
      <IconButton
        icon={<FaTwitter size={size === "medium" ? MEDIUM_SIZE : SMALL_SIZE} />}
        link={socialLinks.twitter}
        variant={variant}
      />
      <IconButton
        icon={
          <FaInstagram size={size === "medium" ? MEDIUM_SIZE : SMALL_SIZE} />
        }
        link={socialLinks.instagram}
        variant={variant}
      />
    </div>
  );
};

export default SocialIcons;
