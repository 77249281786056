import React, { FC } from "react";
import { Container } from "react-bootstrap";
import "./success-message.scss";
import { useTranslation } from "react-i18next";

interface SuccessMessageProps {
  titleKey: string;
  contentKey: string;
  isActive?: boolean;
}

const SuccessMessage: FC<SuccessMessageProps> = ({
  titleKey,
  contentKey,
  isActive = false,
}) => {
  const activeClass = isActive ? "active" : "";
  const { t } = useTranslation();

  return (
    <Container className={`success-message ${activeClass}`}>
      <svg viewBox="0 0 76 76" className="success-message-icon icon-checkmark">
        <circle cx="38" cy="38" r="36" fill="#3DC480" />
        <path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M17.7,40.9l10.9,10.9l28.7-28.7"
        />
      </svg>
      <h1 className="success-message-title">{t(titleKey)}</h1>
      <div className="success-message-content">
        <p>{t(contentKey)}</p>
      </div>
    </Container>
  );
};

export default SuccessMessage;
