import React from "react";
import { useTranslation } from "react-i18next";
import Page from "./Page";
import { Col, Container, Row } from "react-bootstrap";

const AboutUsPage: React.FC = () => {
  const { t } = useTranslation();

  const description = t("aboutUs.longDescription")
    .split("||")
    .map((line) => <p>{line}</p>);

    return (
      <Page>
        <Container className="py-5">
          <h1>{t("aboutUs.title")}</h1>
          <Row className="row mt-5">
            <Col className="col-md-4">
              <img
                src="../images/aboutUs/proprietario.png"
                alt="Proprietario"
                className="img-fluid rounded mb-5"
                style={{ maxWidth: "280px" }}
              />
            </Col>
            <Col className="col-md-8">
              <div>{description}</div>
            </Col>
          </Row>
        </Container>
      </Page>
    );
    
};

export default AboutUsPage;
