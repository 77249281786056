import { FetchResponse, fetchApi } from "utils/apiUtils";

const BACKEND_BASE_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/`;
const apiUrl = (endpoint: string) => `${BACKEND_BASE_URL}${endpoint}/`;

export const addToCart = (productId: number): Promise<FetchResponse<any>> => {
  return fetchApi(apiUrl(`cart/add/${productId}`), { method: "POST" });
};

export const removeFromCart = (productId: number): Promise<FetchResponse<any>> => {
  return fetchApi(apiUrl(`cart/remove/${productId}`), { method: "POST" });
};

export const getCartDetails = (): Promise<FetchResponse<any>> => {
  return fetchApi(apiUrl('cart/detail'), { method: "GET" });
};

export const validatePromoCode = (promoCode: string): Promise<FetchResponse<{ success: boolean, discount?: number, messsage?: string }>> => {
  return fetchApi(apiUrl(`validate_promotional_code/${promoCode}`), { method: "POST" });
};

export const clearPromoCode = (): Promise<FetchResponse<any>> => {
  return fetchApi(apiUrl('clear_promotional_code'), { method: "POST" });
};

export const getPromoCode = (): Promise<FetchResponse<{
  discount: number; success: boolean, promo_code?: string, messsage?: string 
}>> => {
  return fetchApi(apiUrl('get_promotional_code'), { method: "GET" });
};