import React from "react";
import { Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface FilterProps {
  title: string;
  children: React.ReactNode;
}

const Filter: React.FC<FilterProps> = ({ title, children }) => {
  const { t } = useTranslation();
  return (
    <Container className="mb-5">
      <Form>
        <Form.Label>
          <h5>{t('filter.' + title.toLowerCase())}</h5>
        </Form.Label>
        {children}
      </Form>
    </Container>
  );
};

export default Filter;
