import withCarpets from "../components/Carpets/withCarpets";

const PersianCarpetsPage = withCarpets(
  "PERSIAN",
  "services.carpets.persian.title",
  "services.carpets.persian.description",
  "../images/services/persianCarpets.jpg"
);

const OrientalCarpetsPage = withCarpets(
  "ORIENTAL",
  "services.carpets.oriental.title",
  "services.carpets.oriental.description",
  "../images/services/orientalCarpets.jpg"
);

const KilimsPage = withCarpets(
  "KILIM",
  "services.carpets.kilim.title",
  "services.carpets.kilim.description",
  "../images/services/kilims.jpg"
);

export { PersianCarpetsPage, OrientalCarpetsPage, KilimsPage };
