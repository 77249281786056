import React from 'react';

export const calculatePriceAfterDiscount = (price: number, sale?: number) => {
  if (!sale) return price;
  return price - (price * sale / 100);
}

export const calculateTotal = (cart: any) => {
  return cart.reduce((total: number, item: any) => total + item.price, 0);
}

export const renderPrice = (price: number, sale?: number) => {
  if (sale) {
    const salePrice = calculatePriceAfterDiscount(price, sale);
    return (
      <div className="text-tertiary">
        <span className="text-decoration-line-through smaller-text">{price} €</span>
        <span><h5>{salePrice} €</h5></span>
      </div>
    );
  }
  return <h5 className="text-primary">{price} €</h5>;
};

export const renderCartPrice = (price: number, sale?: number) => {
  if (sale) {
    const salePrice = calculatePriceAfterDiscount(price, sale);
    return (
      <div className="text-tertiary">
        <span className="text-decoration-line-through smaller-text">{price} €</span>
        <span>{salePrice} €</span>
      </div>
    );
  }
  return <span>{price} €</span>;
};

