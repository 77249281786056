import React from "react";

interface LogoProps {
  color?: "black" | "white";
}

const Logo: React.FC<LogoProps> = ({ color = "white" }) => {
  const className = `logo ${color === "black" ? "black" : ""}`;

  return <img src="../images/logo/logo.jpg" alt="logo" className={className} />;
};

export default Logo;
