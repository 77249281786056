import React from 'react';
import { Container } from 'react-bootstrap';
import Page from './Page';
import ClientDetails from 'components/Order/ClientDetails';

const ClientDetailsPage: React.FC = () => {
  return (
    <Page>
      <Container className="my-5">
        <ClientDetails />
      </Container>
    </Page>
  );
};

export default ClientDetailsPage;
