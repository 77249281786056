export { default as Navigation } from "./Navigation/Navigation";
export { default as Footer } from "./Footer/CustomFooter";
export { default as HomePage } from "../pages/HomePage";
export { default as Contatti } from "../pages/ContactsPage";
export { default as LavaggioRestauro } from "../pages/WashingRestorationPage";
export { default as BorseCuscini } from "../pages/BagsPillowsPage";
export { PersianCarpetsPage as TappetiPersiani } from "../pages/CarpetsPages";
export { OrientalCarpetsPage as TappetiOrientali } from "../pages/CarpetsPages";
export { KilimsPage as Kilim } from "../pages/CarpetsPages";
export { default as ChiSiamo } from "../pages/AboutUsPage";
export { default as RisultatiRicerca } from "../pages/SearchResultsPage";
export { default as Blogs } from "../pages/BlogsPage";

console.log(`
   Said Tappeti    ---   2024



   |||||||||||||||||||||||
   _______________________
  /                       \\
 /                         \\
|    ___________________    |
|   |                   |   |
|   |     _________     |   |
|   |    /         \\    |   |
|   |   /___________\\   |   |
|   |   \\___________/   |   |
|   |    \\_________/    |   |
|   |                   |   |
|   |                   |   |
|   |                   |   |
|   |                   |   |
|   |     _________     |   |
|   |    /         \\    |   |
|   |   /___________\\   |   |
|   |   \\___________/   |   |
|   |    \\_________/    |   |
|   |                   |   |
|   |___________________|   |
|  /|                   |\\  |
| / |                   | \\ |
|/  |                   |  \\|
\\   |                   |   /
 \\__|___________________|__/
   |||||||||||||||||||||||
`);
