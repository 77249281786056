import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Cart from 'components/Order/Cart';
import EmptyCart from 'components/Order/EmptyCart';
import PriceDetails from 'components/Order/PriceDetails';
import LoadingSpinner from 'components/Shared/Loading/LoadingSpinner';
import { fetchCartDetails } from 'store/cartSlice';
import { RootState } from 'store/types';
import ScrollReveal from 'components/Shared/ScrollReveal/ScrollReveal';
import Lottie from 'react-lottie';
import animationData from '../assets/animations/arrowRight.json';
import Page from './Page';

const mapStateToProps = (state: RootState) => ({
  loading: state.cart.loading,
  cart: state.cart.items,
});

const mapDispatchToProps = {
  fetchCartDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type CartPageProps = PropsFromRedux;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  }
};

const CartPage: React.FC<CartPageProps> = ({ cart, loading, fetchCartDetails }) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchCartDetails();
  }, [fetchCartDetails]);

  return (
    <Page>
      <Container className="my-5">
        <h1>{t('cart.title')}</h1>
        {loading ? (
          <LoadingSpinner />
        ) : cart?.length > 0 ? (
          <ScrollReveal>
            <Row>
              <Col md={8}>
                <Cart cart={cart} />
              </Col>
              <Col md={4}>
                <PriceDetails cart={cart} />
                <Button className="w-100 gap-2" variant="primary" href="/dettagliCliente">
                  {t('cart.checkout')}
                  <div className="flex align-items-center ml-2">
                    <Lottie options={defaultOptions} height={30} width={30} />
                  </div>
                </Button>
              </Col>
            </Row>
          </ScrollReveal>
        ) : (
          <EmptyCart />
        )}
      </Container>
    </Page>
  );
};

export default connector(CartPage);
