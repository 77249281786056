import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PaginationState {
  currentPage: number;
  lastViewedCategory: string | null;
}

const initialState: PaginationState = {
  currentPage: 1,
  lastViewedCategory: null,
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    resetPage(state) {
      state.currentPage = 1;
    },
    setLastViewedCategory(state, action: PayloadAction<string>) {
      state.lastViewedCategory = action.payload;
    },
  },
});

export const { setCurrentPage, resetPage, setLastViewedCategory } = paginationSlice.actions;
export default paginationSlice.reducer;
