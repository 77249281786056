import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IProduct } from "types/productTypes";
import { Container } from "react-bootstrap";
import { fetchProducts } from "api/productsService";
import LoadingSpinner from "components/Shared/Loading/LoadingSpinner";

const withProducts = (
  endpoint: string,
  titleKey: string,
  descKey: string,
  image: string,
  WrappedComponent: React.ComponentType<any>,
  limit?: number
) => {
  return () => {
    const { t } = useTranslation();
    const [products, setProducts] = useState<IProduct[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<null | string>(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetchProducts(endpoint);
          if (response.status === "success") {
            setProducts(limit && response.data ? response.data.slice(0, limit) : response.data || []);
          } else {
            setError(response.message || "Failed to fetch data");
          }
        } catch (err) {
          const errorMessage = err instanceof Error ? err.message : "Failed to fetch data";
          setError(errorMessage);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }, [endpoint, limit]);

    if (loading) {
      return <div className="d-flex justify-content-center align-items-center" 
      style={{ 
        height: '100vh', 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100vw' 
      }}><LoadingSpinner /> </div>;
    }

    return error ? (
      <Container>{error}</Container>
    ) : (
      <WrappedComponent
        title={t(titleKey)}
        description={t(descKey)}
        image={image}
        items={products}
      />
    );
  };
};

export default withProducts;
