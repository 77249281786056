import React from "react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

const SearchOpener = ({ toggleModal }) => {
  const { t } = useTranslation();

  return (
    <div role="button" onClick={toggleModal}>
      <FaSearch className="ml-2" />
    </div>
  );
};

export default SearchOpener;
