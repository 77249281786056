import React, { useEffect } from 'react';
import { scrollToTop } from 'utils/navigationUtils';

const Page = ({ children }) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      {children}
    </>
  );
};

export default Page;
