import React from "react";
import { Container } from "react-bootstrap";
import ScrollReveal from "../ScrollReveal/ScrollReveal";
import { useTranslation } from "react-i18next";

export interface SectionProps {
  titleKey?: string;
  descriptionKey?: string;
  useContainer?: boolean;
  component: React.ReactNode;
  customTitle?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({
  titleKey,
  descriptionKey,
  useContainer = true,
  component,
  customTitle, 
}) => {
  const { t } = useTranslation();
  return (
    <div className="mb-5">
      <ScrollReveal>
        {useContainer ? (
          <Container>
            {customTitle ? (
              <>
                {customTitle}
                <hr className="bottom-line" />
              </>
            ) : (
              titleKey && (
                <>
                  <h1>{t(titleKey)}</h1>
                  <hr className="bottom-line" />
                </>
              )
            )}
            {descriptionKey && <p>{t(descriptionKey)}</p>}
            {component}
          </Container>
        ) : (
          <>
            {customTitle ? (
              <>
                {customTitle}
                <hr className="bottom-line" />
              </>
            ) : (
              titleKey && (
                <>
                  <h2>{t(titleKey)}</h2>
                  <hr className="bottom-line" />
                </>
              )
            )}
            {descriptionKey && <p>{t(descriptionKey)}</p>}
            {component}
          </>
        )}
      </ScrollReveal>
    </div>
  );
};

export default Section;
