import React from "react";

interface Props {
  icon: React.ReactNode;
  link: string;
  variant?: "light" | "dark";
}

const IconButton: React.FC<Props> = ({ icon, link, variant }) => {
  return (
    <a
      href={link}
      target="_blank"
      className={`mx-2 ${variant === "light" ? "text-light" : "text-dark"}`}
    >
      {icon}
    </a>
  );
};

export default IconButton;
