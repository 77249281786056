import { getCookie } from "api/cookieService";

export interface FetchOptions extends RequestInit {
  headers?: HeadersInit;
}

export interface FetchResponse<T> {
  status: "success" | "error";
  data?: T;
  message?: string;
}

export const fetchApi = async <T>(url: string, options: FetchOptions): Promise<FetchResponse<T>> => {
  const csrftoken = getCookie("csrftoken");
  options.headers = {
    ...options.headers,
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken || "",
  };
  options.credentials = "include";

  try {
    const response = await fetch(url, options);
    const contentType = response.headers.get("Content-Type") || "";

    const isJson = contentType.includes("application/json");
    const responseBody = isJson ? await response.json() : await response.text();

    if (!response.ok) {
      const message = isJson ? (responseBody as any).message : responseBody;
      console.error("Error response:", message);
      return { status: "error", message: message || "An unknown error occurred." };
    }

    return { status: "success", data: isJson ? (responseBody as T) : undefined, message: isJson ? undefined : responseBody as string };
  } catch (error: unknown) {
    console.error("Caught error:", error);
    const message = error instanceof Error ? error.message : "An unknown error occurred.";
    return { status: "error", message };
  }
};
