import React, { useState, useCallback } from "react";
import SearchOpener from "./SearchOpener";
import SearchModal from "./SearchModal";

const Search: React.FC<{}> = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const toggleModal = useCallback(() => {
    setModalVisible((prev) => !prev);
  }, []);

  return (
    <>
      <SearchOpener toggleModal={toggleModal} />
      <SearchModal modalVisible={modalVisible} toggleModal={toggleModal} />
    </>
  );
};

export default React.memo(Search);
