import React, { useEffect, useState } from 'react';
import { validatePromoCode, getPromoCode } from 'api/cartService';
import { useTranslation } from 'react-i18next';

interface PromoCodeFormProps {
  applyPromoCode: (discount: number, promoCode: string) => void;
}

const PromoCodeForm: React.FC<PromoCodeFormProps> = ({ applyPromoCode }) => {
  const { t } = useTranslation();
  const [promoCode, setPromoCode] = useState('');
  const [appliedPromoCode, setAppliedPromoCode] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkExistingPromoCode = async () => {
      try {
        const response = await getPromoCode();
        if (response.data && response.data.success && response.data.promo_code) {
          setAppliedPromoCode(response.data.promo_code);
          applyPromoCode(response.data.discount || 0, response.data.promo_code);
        }
      } catch (error) {
        setError(t('cart.invalidPromoCode'));
      }
    };

    checkExistingPromoCode();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await validatePromoCode(promoCode);

      if (response.data && response.status === 'success' && response.data.success) {
        applyPromoCode(response.data.discount || 0, promoCode);
        setAppliedPromoCode(promoCode);
        setError(null);
      } else {
        setError(t('cart.invalidPromoCode'));
      }
    } catch (err) {
      setError(t('cart.invalidPromoCode'));
    }
  };

  return (
    <>
      {appliedPromoCode && <span><b> {t('cart.appliedPromoCode')}: <p className="text-secondary">{appliedPromoCode}</p></b></span>}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            placeholder="Enter promo code"
          />
        </div>
        {error && <p className="text-danger">{error}</p>}
        <button type="submit" className="btn btn-primary">{t('cart.applyPromoCode')}</button>
      </form>
    </>
  );
};

export default PromoCodeForm;
