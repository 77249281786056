import { subscribeToNewsletter } from "api/newsletterService";
import React, { useState, FormEvent } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaBell } from "react-icons/fa";


const NewsletterInput: React.FC = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [messageInfo, setMessageInfo] = useState<{
    text: string;
    isSuccess: boolean;
  } | null>(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      await subscribeToNewsletter(formData);
      setMessageInfo({ text: "Subscribed successfully", isSuccess: true });
    } catch (error) {
      setMessageInfo({ text: "An error occurred :(", isSuccess: false });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="d-flex justify-content-center mb-4">
      <Form onSubmit={handleSubmit}>
        <div className="d-flex align-items-center">
          <Form.Group className="d-flex align-items-center mb-0 mr-2">
            <Form.Label className="mb-0">{t("contacts.newsletter")}</Form.Label>
            <Form.Control
              name="email"
              type="email"
              className="ml-2"
              placeholder={t("input.email.placeholder")}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Button type="submit" className="m-2 gap-2" disabled={loading}>
            {loading ? t("loading") : t("contacts.subscribe")}
            <FaBell />
          </Button>
        </div>
      </Form>
      {messageInfo && (
        <div className={`text-${messageInfo.isSuccess ? "success" : "danger"}`}>
          {messageInfo.text}
        </div>
      )}
    </Container>
  );
};

export default NewsletterInput;
