import React from "react";
import { Container } from "react-bootstrap";
import SocialIcons from "components/Shared/Icons/SocialIcons";
import { useTranslation } from "react-i18next";
import NewsletterInput from "components/NewsLetter/NewsLetterInput";

const ListSection = ({ title, items }) => (
  <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
    <h5 className="text-uppercase">{title}</h5>
    <ul className="list-unstyled mb-0">
      {items.map((item, index) => (
        <li key={index}>
          <a href={item.link} className="text-white">
            {item.text}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

const CustomFooter = () => {
  const { t } = useTranslation();

  const sections = [
    {
      title: t("information.title"),
      items: [
        { text: t("aboutUs.title"), link: "/chiSiamo" },
        { text: t("contacts.title"), link: "/contatti" },
      ],
    },
    {
      title: t("services.carpets.title"),
      items: [
        { text: t("services.carpets.persian.title"), link: "/tappetiPersiani" },
        {
          text: t("services.carpets.oriental.title"),
          link: "/tappetiOrientali",
        },
        { text: t("services.carpets.kilim.title"), link: "/kilim" },
      ],
    },
    {
      title: t("services.otherProducts.title"),
      items: [
        {
          text: t("services.bagsPillows.title"),
          link: "/borseCuscini",
        },
        {
          text: t("services.washingRestoration.title"),
          link: "/lavaggioRestauro",
        },
      ],
    },
  ];

  return (
    <footer className="text-center bg-dark text-light">
      <Container className="p-4">
        <div className="d-flex justify-content-center p-4">
          <span className="me-5 d-none d-lg-block">
            {t("contacts.socialNetworks")}
          </span>
          <SocialIcons variant="light" />
        </div>

        <NewsletterInput />

        <p className="mb-4">{t("aboutUs.description")}</p>

        <div className="row">
          {sections.map((section, index) => (
            <ListSection
              key={index}
              title={section.title}
              items={section.items}
            />
          ))}
        </div>
      </Container>
      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2024 Copyright
      </div>
    </footer>
  );
};

export default CustomFooter;
