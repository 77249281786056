import { TFunction } from "i18next";
import { ValidationRule } from "types/inputTypes";
import React from "react";
import { find } from "linkifyjs";
import DOMPurify from "dompurify";

const createRule = (
  fieldName: string,
  test: (value: string | undefined) => boolean,
  errorMessage: string,
  errorArgs?: Record<string, any>
): ValidationRule => ({
  name: fieldName,
  test,
  errorField: `input.${fieldName}.label`,
  errorMessage,
  errorArgs,
});

export const validateFields = (
  rules: ValidationRule[],
  setErrors: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >,
  t: TFunction<"translation">,
  formData: Record<string, string>
): boolean => {
  let isValid = true;
  const newErrors: Record<string, string> = {};

  rules.forEach(({ name, test, errorMessage, errorField, errorArgs }) => {
    if (!test(formData[name])) {
      newErrors[name] =
        t("input.field") +
        " " +
        t(errorField).toLowerCase() +
        " " +
        t(errorMessage, errorArgs).toLowerCase();
      isValid = false;
    }
  });

  setErrors(newErrors);
  return isValid;
};

export const generateCommonRules = (
  fieldName: string,
  minLength: number,
  maxLength: number,
  isRequired = true
): ValidationRule[] => {
  const rules: ValidationRule[] = [
    createRule(
      fieldName,
      (value) => (value ? value.length >= minLength : true),
      "input.validation.minLength",
      { minLength }
    ),
    createRule(
      fieldName,
      (value) => (value ? value.length <= maxLength : true),
      "input.validation.maxLength",
      { maxLength }
    ),
    createRule(
      fieldName,
      (value) => (value ? value.trim().length > 0 : true),
      "input.validation.notOnlySpaces"
    ),
  ];

  if (isRequired) {
    rules.unshift(createRule(fieldName, Boolean, "input.validation.required"));
  }

  return rules;
};

export const generateNoLinksRule = (fieldName: string): ValidationRule =>
  createRule(
    fieldName,
    (value) => (value ? !find(value).length : true),
    `input.validation.noLinks`
  );

export const generateNotOnlySpacesRule = (fieldName: string): ValidationRule =>
  createRule(
    fieldName,
    (value) => (value ? value.trim().length > 0 : true),
    `input.validation.notOnlySpaces`
  );

export const generateValidEmailRule = (fieldName: string): ValidationRule =>
  createRule(
    fieldName,
    (value) =>
      value
        ? value.includes("@") && value.includes(".") && !value.includes("@.")
        : true,
    `input.validation.invalid`
  );

export const generateNoSpacesRule = (fieldName: string): ValidationRule =>
  createRule(
    fieldName,
    (value) => (value ? !value.includes(" ") && !value.includes("\t") : true),
    `input.validation.noSpaces`
);

export const generateCountrySelectedRule = (fieldName: string): ValidationRule =>
  createRule(
    fieldName,
    (value) => value !== undefined && value !== "",
    `input.validation.required`
  );

export const getTodayDate = (): string => {
  const today = new Date();
  return `${String(today.getDate()).padStart(2, "0")}.${String(
    today.getMonth() + 1
  ).padStart(2, "0")}.${today.getFullYear()}`;
};

export const sanitizeInput = (input: any) => DOMPurify.sanitize(input);
