import React from "react";
import { Container, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SearchBar from "./SearchBar";

const SearchModal = ({ modalVisible, toggleModal }) => {
  const { t } = useTranslation();

  return (
    <Modal show={modalVisible} onHide={toggleModal} centered size="lg" style={{borderRadius: "15px"}}>
      <Modal.Header closeButton className="align-items-center">
        <Modal.Title>{t("input.search.label")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <SearchBar
            toggleModal={toggleModal}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SearchModal;
