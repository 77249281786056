import React from "react";
import './page-header.scss'

interface Props {
  title: string;
  description: string;
  image: string;
}

const PageHeader: React.FC<Props> = ({ title, description, image }) => {
  const backgroundStyle = {
    backgroundImage: `url(${image})`,
  };

  return (
    <>
      <div className="page-header text-light secondary-gradient">
        <div className="page-header-image" style={backgroundStyle}></div>
        <div className="page-header-content">
          <h1>{title}</h1>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
