import React from "react";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const NotFound: React.FC = () => {
    const { t } = useTranslation();
  return (
    <Container className="text-center d-flex align-items-center justify-content-center not-found-container">
      <span className="font-weight-bold not-found-code">{t("notFound.title")}</span>
      <h4 className="mt-1 font-weight-bold not-found-text">{t("notFound.description")}</h4>
      <Button href="/" className="mt-3 btn-lg" variant="outline-dark">
        {t("notFound.backHome")}
      </Button>
    </Container>
  );
};

export default NotFound;
