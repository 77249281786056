export const LANGUAGE_OPTIONS = [
  { value: "en", label: "English", flag: "../flags/uk.png" },
  { value: "it", label: "Italian", flag: "../flags/it.png" },
];

export type LanguageOption = {
  value: string;
  label: string;
  flag: string;
};
