import React, { useEffect, useState, useCallback } from "react";
import { Button, Container } from "react-bootstrap";
import PageHeader from "../Shared/Page/PageHeader";
import ProductsList from "components/Products/ProductsList";
import { IProduct } from "types/productTypes";
import { FilterSetting } from "types/filterTypes";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import FiltersDrawer from "./Filters/FiltersDrawer";
import Page from "pages/Page";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "store/types";
import { setCurrentPage } from "store/paginationSlice";

interface Props {
  title: string;
  description: string;
  image: string;
  items: IProduct[];
  filters: FilterSetting[];
  imageHeader?: boolean;
}

const ProductsView: React.FC<Props> = ({
  title,
  description,
  image,
  items,
  filters,
  imageHeader = true
}) => {
  const { t } = useTranslation();
  const [filteredItems, setFilteredItems] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 20;

  const currentPage = useSelector((state: RootState) => state.pagination.currentPage);

  const dispatch = useDispatch();

  const loadProducts = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      try {
        const filtered = items.filter((item) => {
          const matchesFilters = filters.every((filter) => {
            if (filter.type === "inRange") {
              const field = filter.title.toLowerCase();
              if (field === 'price') {
                return item[field] * (1 - item['sale'] / 100) >= filter.min && item[field] * (1 - item['sale'] / 100) <= filter.max;
              }
              return item[field] >= filter.min && item[field] <= filter.max;
            } else if (filter.type === "multiSelect") {
              return filter.selectedOptions.length === 0 || filter.selectedOptions.includes(item[filter.title.toLowerCase()]);
            }
            return true;
          });
          return matchesFilters;
        });
        setFilteredItems(filtered);
      } catch (error) {
        console.error("Failed to fetch products:", error);
      } finally {
        setLoading(false);
      }
    });
  }, [filters, items]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageChange = (pageNumber: number) => {
    dispatch(setCurrentPage(pageNumber));
    scrollToTop();
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(setCurrentPage(currentPage + 1));
      scrollToTop();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
      scrollToTop();
    }
  };

  const paginatedItems = filteredItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Page>
      {imageHeader && <PageHeader title={title} description={description} image={image} />}
      {!imageHeader && <Container className="mt-5"><h1>{title}</h1></Container>}
      <Container className="my-5">
        <FiltersDrawer filters={filters} />
          <ProductsList products={paginatedItems} />
          <div className="d-flex justify-content-between align-items-center">
            <Button
              variant="light"
              className="gap-2"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaArrowLeft /> {t("previous")}
            </Button>
            <div className="text-center">
              {Array.from({ length: totalPages }, (_, index) => (
                <Button
                  key={index + 1}
                  variant={currentPage === index + 1 ? "secondary" : "light"}
                  onClick={() => handlePageChange(index + 1)}
                  className="mx-1"
                >
                  {index + 1}
                </Button>
              ))}
            </div>
            <Button
              variant="light"
              className="gap-2"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              {t("next")} <FaArrowRight />
            </Button>
          </div>
      </Container>
    </Page>
  );
};

export default ProductsView;
