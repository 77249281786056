import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getCartDetails } from 'api/cartService';
import { IProduct } from 'types/productTypes';
import {addToCart as addToCartApi} from 'api/cartService';

interface CartState {
  items: IProduct[];
  loading: boolean;
  count: number;
}

const initialState: CartState = {
  items: [],
  loading: false,
  count: 0,
};

export const fetchCartDetails = createAsyncThunk<IProduct[], void, { rejectValue: string }>(
  'cart/fetchCartDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCartDetails();
      return response.data?.cart || [];
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addToCart = createAsyncThunk<void, number, { rejectValue: string }>(
  'cart/addToCart',
  async (productId, { dispatch, rejectWithValue }) => {
    try {
      await addToCartApi(productId);
      dispatch(fetchCartDetails());
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setItems(state, action: PayloadAction<IProduct[]>) {
      state.items = action.payload;
      state.count = action.payload.length;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCartDetails.fulfilled, (state, action: PayloadAction<IProduct[]>) => {
        state.items = action.payload;
        state.count = action.payload.length;
        state.loading = false;
      })
      .addCase(fetchCartDetails.rejected, (state) => {
        state.loading = false;
      })
  },
});

export const { setLoading, setItems } = cartSlice.actions;
export default cartSlice.reducer;
