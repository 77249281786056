import React from "react";
import HomeIntro from "../components/Home/Intro/Intro";
import Services from "../components/Home/Services";
import Contacts from "components/Contacts/Contacts";
import Section, { SectionProps } from "components/Shared/Section/Section";
import Video from "components/Home/Video";
import Page from "./Page";

const SECTIONS: SectionProps[] = [
  {
    component: <HomeIntro />,
    useContainer: false,
  },
  {
    titleKey: "services.title",
    descriptionKey: "services.description",
    component: <Services />,
  },
  {
    component: <Video />,
    useContainer: false,
  },
  {
    titleKey: "contacts.title",
    descriptionKey: "contacts.description",
    component: <Contacts />,
  },

];

const HomePage: React.FC = () => (
  <Page>
    {SECTIONS.map((sectionProps, index) => (
      <Section key={index} {...sectionProps} />
    ))}
  </Page>
);

export default HomePage;
