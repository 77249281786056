import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import DropdownMenu from '../Shared/Menu/DropdownMenu';
import { useTranslation } from 'react-i18next';
import { LINK_ITEMS as NAV_ITEMS } from 'constants/linkConstants';
import { FaShoppingCart, FaTags } from 'react-icons/fa';
import Logo from 'components/Shared/Logo/Logo';
import Search from 'components/Search/Search';

const Navigation: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Navbar collapseOnSelect expand="md" variant="light" bg="light">
      <Navbar.Brand as={Link} to="/">
        <Logo color="black" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          {NAV_ITEMS.map((item, idx) => {
            const isActive = location.pathname === "/".concat(t(item.hrefKey));
            const isSaleItem = item.nameKey === "services.sales.title";
            const classNames = [
              isActive ? "active-nav-item" : "",
              isSaleItem ? "sale-item" : "",
            ].join(" ").trim();

            return item.subItems ? (
              <DropdownMenu item={item} idx={idx} key={`dropdown-menu-${idx}`} />
            ) : (
              <Nav.Link
                as={Link}
                to={t(item.hrefKey)}
                key={`nav-item-${idx}`}
                className={classNames}
              >
                {isSaleItem && <FaTags className="mr-2" />}
                {t(item.nameKey)}
              </Nav.Link>
            );
          })}
          {<Nav.Link as={Link} to="/carrello">
            <FaShoppingCart size={24} />
          </Nav.Link>}
          <Nav.Link>
            <Search />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
