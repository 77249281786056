import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { InRangeFilter } from "types/filterTypes";
import Filter from "./Filter";

const FilterInRange: React.FC<InRangeFilter> = ({ title, min, max, minRange, maxRange, setMin, setMax }) => {
  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = parseInt(e.target.value, 10);
    if (val < minRange) val = minRange;
    if (val > max) val = max;
    setMin(val);
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = parseInt(e.target.value, 10);
    if (val > maxRange) val = maxRange;
    if (val < min) val = min;
    setMax(val);
  };

  return (
    <Filter title={title}>
      <Row className="justify-content-between">
        <Col xs={6} className="flex-grow-1">
          <Form.Label>Min</Form.Label>
          <Form.Control
            type="number"
            value={min}
            onChange={handleMinChange}
            min={minRange}
            max={max}
            style={{minWidth: "max-content"}}
          />
        </Col>
        <Col xs={6} className="flex-grow-1">
          <Form.Label>Max</Form.Label>
          <Form.Control
            type="number"
            value={max}
            onChange={handleMaxChange}
            min={min}
            max={maxRange}
            style={{minWidth: "max-content"}}
          />
        </Col>
      </Row>
    </Filter>
  );
};

export default FilterInRange;
