import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaArrowRight, FaShoppingCart } from 'react-icons/fa';

const EmptyCart: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center p-5 mt-5">
      <FaShoppingCart size={100} className="text-muted mb-3" />
      <h5>{t('cart.empty')}</h5>
      <Button className="mt-5 gap-2" href="/sconti" variant="primary" size="lg">
        {t('cart.startShopping')}
        <FaArrowRight />
      </Button>
    </div>
  );
};

export default EmptyCart;
