import React, { FC, useMemo, useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { IProduct } from "types/productTypes";
import { renderPrice } from "../../utils/priceUtils";
import { FaCartPlus, FaCheck } from "react-icons/fa";
import AddToCartModal from "components/Shared/Modals/AddToCartModal";
import { addToCart } from 'store/cartSlice';
import { AppDispatch } from 'store/store';
import LoadingSpinner from "components/Shared/Loading/LoadingSpinner";

interface IProductCardProps {
  details: IProduct;
  cart: IProduct[];
}

const ProductCard: FC<IProductCardProps> = ({
  details: { id, name, price, height, width, sale, photo_url }, cart,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [added, setAdded] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const isInCart = cart.some(item => item.id === id);

  const priceLabel = useMemo(() => renderPrice(price, sale), [price, sale]);

  const handleAddToCart = async (id: number) => {
    try {
      setLoading(true);
      await dispatch(addToCart(id));
      setShowModal(true);
      setLoading(false);
      setAdded(true);
    } catch (error) {
      console.error('There was an error adding the item to the cart!', error);
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Container className="mb-5 mx-2">
      <Card className="product-card">
        {(sale && <span className="badge badge-danger">Sale</span>) || ""}
        <Link to={`/product/${id}`} className="text-decoration-none">
          <Card.Img
            variant="top"
            src={photo_url}
            alt={name}
            className="product-tumb p-3"
          />
        </Link>
        <Card.Body className="product-details d-flex flex-column text-left">
          <Card.Title>
            <Link to={`/product/${id}`} className="text-decoration-none">
              <h5 className="font-weight-bold text-truncate">{name}</h5>
            </Link>
          </Card.Title>
          <Card.Text>
            {height} x {width} cm
          </Card.Text>
          <div className="d-flex justify-content-between align-items-center">
            <span>{priceLabel}</span>
            <Button variant="light" size="sm" onClick={() => handleAddToCart(id)} disabled={added || isInCart || loading}>
              {added || isInCart ? (
                <FaCheck />
              ) : (
                <FaCartPlus />
              )}
            </Button>
          </div>
        </Card.Body>
      </Card>
      <AddToCartModal
        show={showModal}
        handleClose={handleCloseModal}
      />
    </Container>
  );
};

export default ProductCard;
