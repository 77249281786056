import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Intro.scss";

const Intro = () => {
  const { t } = useTranslation();
  const [quotePart1, quotePart2] = t("home.description").split(",");

  return (
    <div className="intro text-light">
      <Container>
        <Row>
          <Col lg={8}>
            <div className="intro-message">
              <h1 className="animated slideInLeft">
                {quotePart1},
                <span className="highlight">{quotePart2}</span>
              </h1>
            </div>
            <Button href="/tappetiPersiani">{t('shopNow')}</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Intro;
