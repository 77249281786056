import List from 'components/Shared/List/List';
import InfoCard from 'components/Shared/Section/InfoCard/InfoCard';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const WashingService = () => {
  const { t } = useTranslation();

  const processSteps = [
    t("services.washingRestoration.washing.process.inspection"),
    t("services.washingRestoration.washing.process.dusting"),
    t("services.washingRestoration.washing.process.washing"),
    t("services.washingRestoration.washing.process.rinsing"),
    t("services.washingRestoration.washing.process.drying"),
    t("services.washingRestoration.washing.process.check")
  ];

  return (
    <InfoCard>
      <Card.Title as="h2">{t("services.washingRestoration.washing.title")}</Card.Title>
      <Card.Text>{t("services.washingRestoration.washing.description")}</Card.Text>
      <Card.Title as="h4">{t("services.washingRestoration.washing.process.title")}</Card.Title>
      <List items={processSteps} />
      <Card.Text>{t("services.washingRestoration.washing.maintenance")}</Card.Text>
    </InfoCard>
  );
};

export default WashingService;
