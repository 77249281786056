import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { ConnectedProps, connect } from 'react-redux';
import PriceDetails from 'components/Order/PriceDetails';
import { RootState } from 'store/types';
import { useTranslation } from 'react-i18next';
import ScrollReveal from 'components/Shared/ScrollReveal/ScrollReveal';
import { CLIENT_DETAILS_FORM_FIELDS, CLIENT_DETAILS_FORM_VALIDATION_RULES, EU_COUNTRY_KEYS } from 'constants/inputConstants';
import { validateFields } from 'utils/inputUtils';
import { fetchCartDetails } from 'store/cartSlice';
import { createOrder } from 'api/orderService';
import { FaCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/Shared/Loading/LoadingSpinner';

const mapStateToProps = (state: RootState) => ({
  loading: state.cart.loading,
  cart: state.cart.items,
});

const mapDispatchToProps = {
  fetchCartDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type CartPageProps = PropsFromRedux;

const ClientDetails: React.FC<CartPageProps> = ({ cart, loading, fetchCartDetails }) => {
  const [orderDetails, setOrderDetails] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCartDetails();
  }, [fetchCartDetails]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOrderDetails({ ...orderDetails, [name]: value });
  };

  const startOrder = async (data: any) => {
    if (!validateFields(CLIENT_DETAILS_FORM_VALIDATION_RULES, setErrors, t, orderDetails)) return;

    setIsCreatingOrder(true);
    try {
      await createOrder(data);
      navigate(`/checkout`);
    } catch (error) {
      alert('There was an error creating the order! ' + error);
    } finally {
      setIsCreatingOrder(false);
    }
  };

  return (
    <ScrollReveal>
      <Container>
        <h1>{t('order.clientDetails')}</h1>
        <Row className="my-5">
          <Col md={7}>
            <Form>
              {CLIENT_DETAILS_FORM_FIELDS.map((field, index) => (
                <Form.Group controlId={field.name} className="mb-3" key={index}>
                  <Form.Label>{t(field.label)}</Form.Label>
                  {field.type === 'select' ? (
                    <Form.Control
                      as="select"
                      name={field.name}
                      value={orderDetails[field.name] || ""}
                      placeholder={t(field.placeholder)}
                      className={`${field.className} ${errors[field.name] ? "is-invalid" : ""
                        }`}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">{t('input.selectCountry')}</option>
                      {EU_COUNTRY_KEYS.map((key) => (
                        <option key={key} value={t(key)}>{t(key)}</option>
                      ))}
                    </Form.Control>
                  ) : (
                    <Form.Control
                      type={field.type}
                      name={field.name}
                      value={orderDetails[field.name] || ""}
                      onChange={handleInputChange}
                      placeholder={t(field.placeholder)}
                      className={`${field.className} ${errors[field.name] ? "is-invalid" : ""
                        }`}
                      required
                    />
                  )}
                  {errors[field.name] && (
                    <div className="invalid-feedback">{errors[field.name]}</div>
                  )}
                </Form.Group>
              ))}
            </Form>
          </Col>
          <Col md={5}>
            <PriceDetails cart={cart} />
            <Button
              variant="primary"
              className="w-100 mt-3 gap-2"
              onClick={() => startOrder(orderDetails)}
              disabled={isCreatingOrder || loading}
            >
              {t('order.placeOrder')} <FaCheck />
            </Button>
          </Col>
        </Row>
      </Container>
    </ScrollReveal>
  );
};

export default connector(ClientDetails);
