import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IProduct } from "types/productTypes";
import { Container } from "react-bootstrap";
import { fetchSearchResults } from "api/searchService";
import LoadingSpinner from "components/Shared/Loading/LoadingSpinner";
import { useSearch } from "./SearchContext";

const withSearchResults = (
  titleKey: string,
  descKey: string,
  WrappedComponent: React.ComponentType<any>,
) => {
  return (props: any) => {
    const { t } = useTranslation();
    const { query } = useSearch();
    const [products, setProducts] = useState<IProduct[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<null | string>(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetchSearchResults(query);
          setProducts(response.data);
        } catch (err) {
          console.error("Failed to fetch data", err);
          setError("Failed to fetch data");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }, [query]);

    if (loading) {
      return (
        <div className="d-flex justify-content-center align-items-center" 
             style={{ height: '100vh', position: 'fixed', top: 0, left: 0, width: '100vw' }}>
          <LoadingSpinner />
        </div>
      );
    }

    return error ? (
      <Container>{error}</Container>
    ) : (
      <WrappedComponent
        title={t(titleKey) + " \"" + query + "\""}
        description={t(descKey)}
        image={""}
        items={products}
        {...props}
      />
    );
  };
};

export default withSearchResults;
