import { ValidationRule } from "types/inputTypes";
import {
  generateCommonRules,
  generateCountrySelectedRule,
  generateNoLinksRule,
  generateNoSpacesRule,
  generateValidEmailRule,
} from "utils/inputUtils";

export const CONTACT_FORM_VALIDATION_RULES: ValidationRule[] = [
  ...[...generateCommonRules("name", 2, 50), generateNoLinksRule("name")],
  ...[
    ...generateCommonRules("email", 5, 50),
    generateValidEmailRule("email"),
    generateNoSpacesRule("email"),
  ],
  ...[
    ...generateCommonRules("message", 10, 500),
    generateNoLinksRule("message"),
  ],
];

export const SEARCHBAR_VALIDATION_RULES: ValidationRule[] = [
  ...generateCommonRules("search", 2, 50, true),
  generateNoLinksRule("search"),
];

export const CONTACT_FORM_FIELDS = [
  {
    name: "name",
    type: "text",
    placeholder: "input.name.placeholder",
    className: "form-input",
  },
  {
    name: "email",
    type: "text",
    placeholder: "input.email.placeholder",
    className: "form-input",
  },
  {
    name: "message",
    type: "textarea",
    placeholder: "input.message.placeholder",
    className: "form-textarea",
  },
];

export const CLIENT_DETAILS_FORM_FIELDS = [
  {
    name: "name",
    label: "input.name.label",
    type: "text",
    placeholder: "input.name.placeholder",
    className: "form-input",
  },
  {
    name: "surname",
    label: "input.surname.label",
    type: "text",
    placeholder: "input.surname.placeholder",
    className: "form-input",
  },
  {
    name: "email",
    label: "input.email.label",
    type: "text",
    placeholder: "input.email.placeholder",
    className: "form-input",
  },
  {
    name: "address",
    label: "input.address.label",
    type: "text",
    placeholder: "input.address.placeholder",
    className: "form-input",
  },
  {
    name: "city",
    label: "input.city.label",
    type: "text",
    placeholder: "input.city.placeholder",
    className: "form-input",
  },
  {
    name: "postal_code",
    label: "input.postal_code.label",
    type: "text",
    placeholder: "input.postal_code.placeholder",
    className: "form-input",
  },
  {
    name: "country",
    label: "input.country.label",
    type: "select",
    placeholder: "input.country.placeholder",
    className: "form-input",
  },
];

export const CLIENT_DETAILS_FORM_VALIDATION_RULES: ValidationRule[] = [
  ...generateCommonRules("name", 2, 50),
  ...generateCommonRules("surname", 2, 50),
  ...[
    ...generateCommonRules("email", 5, 50),
    generateValidEmailRule("email"),
    generateNoSpacesRule("email"),
  ],
  ...generateCommonRules("address", 5, 100),
  ...generateCommonRules("city", 2, 50),
  ...generateCommonRules("postal_code", 2, 20),
  generateNoSpacesRule("postal_code"),
  generateCountrySelectedRule("country")
];


export const EU_COUNTRY_KEYS = [
  'country.austria', 
  'country.belgium', 
  'country.bulgaria', 
  'country.croatia', 
  'country.cyprus', 
  'country.czech_republic', 
  'country.denmark', 
  'country.estonia', 
  'country.finland', 
  'country.france', 
  'country.germany', 
  'country.greece', 
  'country.hungary', 
  'country.ireland', 
  'country.italy', 
  'country.latvia', 
  'country.lithuania', 
  'country.luxembourg', 
  'country.malta', 
  'country.netherlands', 
  'country.poland', 
  'country.portugal', 
  'country.romania', 
  'country.slovakia', 
  'country.slovenia', 
  'country.spain', 
  'country.sweden'
];
function createRule(arg0: string, arg1: (value: any) => boolean, arg2: string): ValidationRule {
  throw new Error("Function not implemented.");
}

