import React from "react";
import { useTranslation } from "react-i18next";
import Page from "./Page";

const BlogsPage: React.FC = () => {
  const { t } = useTranslation();

  return (<></>);
};

export default BlogsPage;
