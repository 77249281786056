import { HierarchicalLinkItem } from "types/linkTypes";

export const buildKey = (commonPrefix: string, ...keys: string[]): string => {
  return [commonPrefix, ...keys].join(".");
};

export const buildLinkItem = (
  baseKey: string,
  subItems?: HierarchicalLinkItem[]
) => ({
  nameKey: buildKey(baseKey, "title"),
  hrefKey: buildKey(baseKey, "href"),
  subItems,
});

export const createServiceItems = (items: HierarchicalLinkItem[]) => {
  return items.map((item, index) => ({
    ...item,
    imageSrc: `./images/services/${
      item.nameKey.split(".").slice(-2, -1)[0]
    }.jpg`,
    tinyImageSrc: `./images/services/${
      item.nameKey.split(".").slice(-2, -1)[0]
    }-tiny.jpg`,
    descriptionKey: buildKey(
      item.nameKey.split(".")[0],
      item.nameKey.split(".").slice(-2, -1)[0],
      "description"
    ),
  }));
};
