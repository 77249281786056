import React from "react";
import { Row } from "react-bootstrap";
import Select from "react-select";
import { MultiSelectFilter } from "types/filterTypes";
import Filter from "./Filter";

const FilterMultiselect: React.FC<MultiSelectFilter> = ({ title, options, selectedOptions, setSelectedOptions }) => {
  const handleOptionChange = (selectedOptions: any) => {
    const selected = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
    setSelectedOptions(selected);
  };

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Filter title={title}>
      <Row onMouseDown={stopPropagation}>
        <Select
          isMulti
          options={options.map(option => ({ value: option, label: option }))}
          value={selectedOptions.map(option => ({ value: option, label: option }))}
          onChange={handleOptionChange}
        />
      </Row>
    </Filter>
  );
};

export default FilterMultiselect;
