import React from "react";
import { Col, Card, Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HierarchicalLinkItem } from "types/linkTypes";
import colorTheme from "constants/colorTheme";
import { SERVICES } from "constants/linkConstants";
import { FaArrowRight } from "react-icons/fa";

interface ServiceCardProps extends HierarchicalLinkItem {
  colorTheme?: string;
}

interface CustomCardHeaderProps {
  hrefKey: string;
  imageSrc: string;
}

const CustomCardHeader: React.FC<CustomCardHeaderProps> = ({ hrefKey, imageSrc}) => {
  const { t } = useTranslation();
  const headerStyle = {
    backgroundImage: `url(${imageSrc})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '350px',
    width: '100%',
    borderRadius: '15px'
  };

  return (
    <Card.Header className="d-flex justify-content-center p-0 rounded" style={{ borderBottom: 'none' }}>
      <a href={"/" + t(hrefKey)} style={{ textDecoration: 'none', width: '100%', height: '100%' }}>
        <div style={headerStyle}></div>
      </a>
    </Card.Header>
  );
};


const ServiceCard: React.FC<ServiceCardProps> = (service) => {
  const { t } = useTranslation();
  const {
    nameKey,
    descriptionKey,
    hrefKey,
    imageSrc
  } = service;

  return (
    <Col md={6} lg={4} className="d-flex mb-5">
      <Card
        className="d-flex flex-column border-0 shadow"
        style={{
          backgroundColor: colorTheme.secondaryLight,
          borderRadius: "15px",
        }}
      >
        <CustomCardHeader
              hrefKey={hrefKey}
              imageSrc={imageSrc ? imageSrc : ""}
        />

        <Card.Body className="flex-grow-1 mt-4 text-center" style={{ paddingTop: "0" }}>
          <Card.Title className="title">
            <h2>{t(nameKey)}</h2>
          </Card.Title>
          <Card.Text className="description">
            {descriptionKey ? t(descriptionKey) : ""}
          </Card.Text>
        </Card.Body>
        <div className="d-flex justify-content-center mb-3">
          <Button className="m-3 gap-2" href={"/" + t(hrefKey)}>
            {t("learnMore")} <FaArrowRight />
          </Button>
        </div>
      </Card>
    </Col>
  );
};

const Services = () => {

  return (
    <div className="my-5">
      <Row>
        {SERVICES.map((service, index) => (
          <ServiceCard
            key={index}
            {...service}
          />
        ))}
      </Row>
    </div>
  );
};

export default Services;
