import React from "react";
import ContactForm from "./Form/ContactForm";
import Map from "../Map/Map";
import { Col, Row } from "react-bootstrap";
import ContactDetails from "./Details/ContactDetails";

const Contacts: React.FC = () => {
  return (
    <Row className="contacts">
      <Col lg={3} className="align-self-center">
        <ContactDetails />
      </Col>
      <Col lg={5} className="align-self-center">
        <ContactForm />
      </Col>
      <Col lg={4} className="align-self-center">
        <Map />
      </Col>
    </Row>
  );
};

export default Contacts;
