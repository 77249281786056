import React, { useState, useEffect } from "react";
import FilterInRange from "./FilterInRange";
import FilterMultiselect from "./FilterMultiselect";
import { Button, Offcanvas, Container, Row } from "react-bootstrap";
import { FaFilter, FaUndo } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { FilterSetting } from "types/filterTypes";

interface FilterDrawerProps {
  filters: FilterSetting[];
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({ filters }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleReset = () => {
    filters.forEach((filter) => {
      if (filter.type === "inRange") {
        filter.setMin(filter.minRange);
        filter.setMax(filter.maxRange);
      } else if (filter.type === "multiSelect") {
        filter.setSelectedOptions([]);
      }
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const offcanvasElement = document.getElementById("offcanvas-filter");
      if (offcanvasElement && !offcanvasElement.contains(event.target as Node)) {
        setShow(false);
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  return (
    <>
      <Row className="justify-content-end mb-2">
        <Button variant="outline-secondary w-auto gap-2" size="sm" onClick={handleShow}>
          <FaFilter/> {t("filter.title")}
        </Button>
      </Row>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        scroll={true}
        id="offcanvas-filter"
        className="pt-5"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("filter.title")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column justify-content-between align-items-center" onMouseDown={(e) => e.stopPropagation()} style={{ overflowY: 'auto' }}>
          <div className="w-100 d-flex flex-column align-items-center">
            {filters.map((filter, index) => (
              <Row key={index} className="w-100">
                {filter.type === "inRange" && <FilterInRange {...filter} />}
                {filter.type === "multiSelect" && <FilterMultiselect {...filter} />}
              </Row>
            ))}
          </div>
          <Row className="w-100 mt-auto">
            <Container className="my-2 d-flex justify-content-center">
              <Button 
                variant="secondary"
                onClick={handleReset}
                size="sm"
              >
                {t("filter.reset")}
                <FaUndo className="ms-2" />
              </Button>
            </Container>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FilterDrawer;
