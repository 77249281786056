import React from 'react';
import Page from './Page';
import { Container } from 'react-bootstrap';
import Checkout from 'components/Order/Checkout';

const CheckoutPage: React.FC = () => {
    return (
        <Page>
            <Container className="my-5">
                <Checkout />
            </Container>
        </Page>
    );
};

export default CheckoutPage;
