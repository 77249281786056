const colorTheme = {
  primary: "#805843ff",
  primaryLight: "#ffffff",
  primaryDark: "#261A1Aff",
  secondary: "#605451",
  secondaryLight: "#c7b2ac",
  secondaryDark: "#201c1b",
  bodyBg: "#ded9d7",
  bodyColor: "#454545"
};

export default colorTheme;
