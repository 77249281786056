import React from "react";
import { Container } from "react-bootstrap";

const Map: React.FC = () => {
  return (
    <Container className="d-flex justify-content-center mb-5">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3091.0441644733482!2d9.10294057595233!3d39.21916267165716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e73404386f7faf%3A0xea6e1b23eb94e7ea!2sSaid%20Tappeti!5e0!3m2!1sen!2sdk!4v1694302404913!5m2!1sen!2sdk"
        width="350"
        height="350"
        title="Said Tappeti location on Google Maps"
      ></iframe>
    </Container>
  );
};

export default Map;
