import React, { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HierarchicalLinkItem } from "types/linkTypes";

export interface NavItemProps {
  item: HierarchicalLinkItem;
  idx: number;
}

const DropdownMenu = ({ item, idx }: NavItemProps) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);

  return (
    <NavDropdown
      title={t(item.nameKey)}
      id={`nav-dropdown-${idx}`}
      show={show}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {item.subItems &&
        item.subItems.map((subItem, subIdx) => (
          <NavDropdown.Item
            as={Link}
            to={t(subItem.hrefKey)}
            key={`dropdown-item-${subIdx}`}
          >
            {t(subItem.nameKey)}
          </NavDropdown.Item>
        ))}
    </NavDropdown>
  );
};

export default DropdownMenu;
