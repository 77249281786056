import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Video.scss';
import { useTranslation } from 'react-i18next';

const Video = () => {
    const { t } = useTranslation();
    
    return (
    <div className="video-container">
        <video className="video-element" loop autoPlay muted>
        <source src={require('./home.mp4')} type="video/mp4" />
        Your browser does not support the video tag.
        </video>
        <div className="color-overlay"></div>
        <div className="video-overlay">
        <h1>{t('home.videoMessage')}</h1>
        </div>
    </div>
    );
};
export default Video;
