import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICarpet } from "types/productTypes";
import CarpetsView from "./CarpetsView";
import { Container } from "react-bootstrap";
import { fetchCarpetsByCategory } from "api/productsService";
import LoadingSpinner from "components/Shared/Loading/LoadingSpinner";

const withCarpets = (
  category: string,
  titleKey: string,
  descKey: string,
  image: string
) => {
  return () => {
    const { t } = useTranslation();
    const [carpets, setCarpets] = useState<ICarpet[]>([]);
    const [colors, setColors] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<null | string>(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetchCarpetsByCategory(category);
          if (response.status === "success") {
            setCarpets(response.data || []);
          } else {
            setError(response.message || "Failed to fetch data");
          }
        } catch (err) {
          const errorMessage = err instanceof Error ? err.message : "Failed to fetch data";
          setError(errorMessage);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }, [category]);

    useEffect(() => {
      if (carpets.length > 0) {
        const uniqueColors = Array.from(new Set(carpets.map(carpet => carpet.color.toLowerCase())));
        setColors(uniqueColors);
      }
    }, [carpets]);

    if (loading) {
      return <LoadingSpinner />;
    }

    return error ? (
      <Container>{error}</Container>
    ) : (
      <CarpetsView
        title={t(titleKey)}
        description={t(descKey)}
        image={image}
        items={carpets}
        colorOptions={colors}
      />
    );
  };
};

export default withCarpets;
