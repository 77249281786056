import React from 'react';
import CustomModal from './CustomModal';
import { useTranslation } from 'react-i18next';
import animationData from '../../../assets/animations/removeFromCart.json';
import Lottie from 'react-lottie';
import { FaTrash, FaWindowClose, FaXingSquare } from 'react-icons/fa';


const RemoveFromCartAnimation: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Lottie options={defaultOptions} height={150} width={150} />
    </div>
  );
};


interface ConfirmRemoveModalProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const ConfirmRemoveModal: React.FC<ConfirmRemoveModalProps> = ({ show, handleClose, handleConfirm }) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      show={show}
      handleClose={handleClose}
      message={t('cart.confirmRemoveMessage')}
      primaryButtonText={t('global.remove')}
      primaryButtonAction={handleConfirm}
      secondaryButtonText={t('global.cancel')}
      secondaryButtonAction={handleClose}
      primaryButtonVariant="danger"
      AnimationComponent={() => <RemoveFromCartAnimation />}
      primaryButtonIcon={<FaTrash />}
      secondaryButtonIcon={<FaWindowClose />}
    />
  );
};

export default ConfirmRemoveModal;
