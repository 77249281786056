import { IBag, ICarpet, IPillow, IProduct } from "types/productTypes";
import { FetchResponse, fetchApi } from "utils/apiUtils";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const fetchCarpetsByCategory = async (category: string): Promise<FetchResponse<ICarpet[]>> => {
  if (typeof category !== "string" || category.trim() === "") {
    throw new Error("Invalid category");
  }
  
  const url = `${BACKEND_BASE_URL}/api/carpets/?category=${encodeURIComponent(category)}`;
  return await fetchApi<ICarpet[]>(url, { method: "GET" });
};

export const fetchProducts = async (endpoint: string): Promise<FetchResponse<IProduct[]>> => {
    const url = `${BACKEND_BASE_URL}/api/${endpoint}/`;
    return await fetchApi<IProduct[]>(url, { method: "GET" });
};

export const fetchProductById = async (productId: number): Promise<FetchResponse<ICarpet | IBag | IPillow>> => {
  if (!productId || typeof productId !== "number") {
    throw new Error("Invalid product ID");
  }

  const url = `${BACKEND_BASE_URL}/api/product/${productId}/`;
  return await fetchApi<ICarpet | IBag | IPillow>(url, { method: "GET" });
};