import List from 'components/Shared/List/List';
import InfoCard from 'components/Shared/Section/InfoCard/InfoCard';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const RestorationService: React.FC = () => {
  const { t } = useTranslation();

  const processSteps = [
    t("services.washingRestoration.restoration.process.evaluation"),
    t("services.washingRestoration.restoration.process.deepCleaning"),
    t("services.washingRestoration.restoration.process.damageRepair.title"),
    t("services.washingRestoration.restoration.process.reinforcement")
  ];

  const nestedProcessSteps = [
    [],
    [],
    [
      t("services.washingRestoration.restoration.process.damageRepair.mending"),
      t("services.washingRestoration.restoration.process.damageRepair.rewaving")
    ],
    []
  ];

  const maintenanceSteps = [
    t("services.washingRestoration.restoration.maintenance.placement"),
    t("services.washingRestoration.restoration.maintenance.regularCleaning"),
    t("services.washingRestoration.restoration.maintenance.periodicInspection"),
    t("services.washingRestoration.restoration.conclusion")
  ];

  return (
    <InfoCard>
      <Card.Title as="h2">{t("services.washingRestoration.restoration.title")}</Card.Title>
      <Card.Text>{t("services.washingRestoration.restoration.description")}</Card.Text>
      <Card.Title as="h4">{t("services.washingRestoration.restoration.process.title")}</Card.Title>
      <List items={processSteps} nestedItems={nestedProcessSteps} />
      <Card.Title as="h4">{t("services.washingRestoration.restoration.maintenance.title")}</Card.Title>
      <List items={maintenanceSteps} />
    </InfoCard>
  );
};

export default RestorationService;
