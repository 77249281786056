import React from 'react';
import { useTranslation } from 'react-i18next';

interface PaymentInformationProps {
    orderId: number | null;
    IBAN: string;
    paypalEmail: string;
}

const PaymentInformation: React.FC<PaymentInformationProps> = ({ orderId, IBAN, paypalEmail }) => {
    const { t } = useTranslation();

    return (
        <section className="my-4 gray-bg p-4 rounded">
            <h2>{t('order.howToPayTitle')}</h2>
            <p>{`${t('order.howToPay')} ${t('order.yourOrderId')} ${orderId}.`}</p>
            <p>{t('order.soonEmail')}</p>

            <h2>{t('order.bankTransfer')}</h2>
            <p><strong>IBAN:</strong> {IBAN}</p>

            <h2>{t('order.paypal')}</h2>
            <p><strong>PayPal Email:</strong> {paypalEmail}</p>

            <div>
                <h2>{t('global.important')}</h2>
                <p>{t('order.orderProcessingInformation')}</p>
                <a href="/contatti">{t('order.questions')}</a>
            </div>
        </section>
    );
};

export default PaymentInformation;
