import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./languages/en.json";
import it from "./languages/it.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    it: { translation: it },
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, 
  },
  detection: {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
  },
});

const storedLanguage = localStorage.getItem("language");

if (storedLanguage) {
  i18n.changeLanguage(storedLanguage);
} else {
  const userLocale = navigator.language;
  const defaultLanguage = userLocale.startsWith('it') ? 'it' : 'en';
  i18n.changeLanguage(defaultLanguage);
}

export default i18n;
