import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/checkmark.json';
import React from "react";

export const Success: React.FC = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  
    return <Lottie options={defaultOptions} height={150} width={150} />;
};