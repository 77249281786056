import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "typeface-roboto";
import "./styles/index.scss";
import "./i18n";
import { routes } from "./routes";
import { Navigation } from "components";
import PageContainer from "components/Shared/Page/PageContainer";
import CustomFooter from "components/Footer/CustomFooter";
import { SearchProvider } from "components/Search/SearchContext";
import ProductDetails from "components/Products/ProductDetails";
import NotFound from "components/Shared/Errors/NotFound";
import { Provider, useDispatch, useSelector } from "react-redux";
import TopBar from "components/TopBar/TopBar";
import store from "store/store";
import ReactGA from 'react-ga4';
import { resetPage, setLastViewedCategory } from "store/paginationSlice"; // Import pagination actions
import { RootState } from "store/types";

const App = () => {
  ReactGA.initialize('G-SF4BD02583');

  return (
    <Provider store={store}>
      <SearchProvider>
        <Router>
          <RouteChangeTracker />
          <div className="fixed-top">
            <TopBar />
            <Navigation />
          </div>
          <PageContainer>
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
              <Route
                path="/product/:id"
                element={<ProductDetails />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </PageContainer>
          <CustomFooter />
        </Router>
      </SearchProvider>
    </Provider>
  );
};

const RouteChangeTracker = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const lastViewedCategory = useSelector((state: RootState) => state.pagination.lastViewedCategory);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });

    const currentCategory = getCategoryFromPath(location.pathname);
    if (currentCategory) {
      if (currentCategory === lastViewedCategory) {
        return;
      }
      
      dispatch(resetPage());

      dispatch(setLastViewedCategory(currentCategory));
    }
  }, [location, dispatch, lastViewedCategory]);

  return null;
};

const getCategoryFromPath = (path: string): string | null => {
  if (path.startsWith('/borseCuscini')) {
    return 'borseCuscini';
  }
  if (path.startsWith('/tappetiPersiani')) {
    return 'tappetiPersiani';
  }
  if (path.startsWith('/tappetiOrientali')) {
    return 'tappetiOrientali';
  }
  if (path.startsWith('/kilim')) {
    return 'kilim';
  }
  if(path.startsWith('/risultatiRicerca')) {
    return 'risultatiRicerca';
  }
  return null;
};

const root = document.getElementById("root") ?? undefined;

if (root) {
  createRoot(root).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error("Root element not found");
}
