import React, { useState } from 'react';
import { Table, Button, Image } from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IProduct } from 'types/productTypes';
import ConfirmRemoveModal from 'components/Shared/Modals/ConfirmRemoveFromCartModal';
import { renderCartPrice } from 'utils/priceUtils';
import { removeFromCart } from 'api/cartService';
import { setItems } from 'store/cartSlice';
import { AppDispatch } from 'store/store';
import LoadingSpinner from 'components/Shared/Loading/LoadingSpinner';

interface CartProps {
  cart: IProduct[];
}

const Cart: React.FC<CartProps> = ({ cart: initialCart }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [cart, setCart] = useState<IProduct[]>(initialCart);
  const [showModal, setShowModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const [loadingProductId, setLoadingProductId] = useState<number | null>(null);
  const [shrinkOutIds, setShrinkOutIds] = useState<number[]>([]);

  const handleShowModal = (productId: number) => {
    setSelectedProductId(productId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedProductId(null);
    setShowModal(false);
  };

  const handleConfirmRemove = async () => {
    if (selectedProductId !== null) {
      setLoadingProductId(selectedProductId);
      setShrinkOutIds((prev) => [...prev, selectedProductId]);

      setTimeout(async () => {
        const oldCart = [...cart];
        const newCart = cart.filter(item => item.id !== selectedProductId);

        setCart(newCart);
        dispatch(setItems(newCart));

        handleCloseModal();

        try {
          await removeFromCart(selectedProductId);
        } catch (error) {
          console.error('There was an error removing the item from the cart!', error);
          dispatch(setItems(oldCart));
          setCart(oldCart);
        } finally {
          setLoadingProductId(null);
          setShrinkOutIds((prev) => prev.filter(id => id !== selectedProductId));
        }
      }, 500);
    }
  };

  return (
    <>
      <Table className='mt-4'>
        <tbody>
          {cart.map(item => (
            <tr key={item.id} className={`align-middle ${shrinkOutIds.includes(item.id) ? 'shrink-out' : ''}`}>
              <td className="align-middle">
                <Link to={`/product/${item.id}`}>
                  <Image src={item.photo_url} thumbnail width={150} className='mr-3' />
                  {item.name}
                </Link>
              </td>
              <td className="align-middle">{renderCartPrice(item.price, item.sale)}</td>
              <td className="align-middle">
                <Button variant="light" size="sm" onClick={() => handleShowModal(item.id)} disabled={loadingProductId === item.id}>
                  {loadingProductId === item.id ? <LoadingSpinner size={20} /> : <FaTrashAlt />}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <ConfirmRemoveModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmRemove}
      />
    </>
  );
};

export default Cart;
