import React, { useState } from "react";
import {
  MIN_PRICE,
  MAX_PRICE,
  MAX_HEIGHT,
  MAX_WIDTH,
  MIN_HEIGHT,
  MIN_WIDTH,
} from "constants/filteringConstants";
import { IProduct } from "types/productTypes";
import ProductsView from "components/Products/ProductsView";
import { FilterSetting } from "types/filterTypes";

interface Props {
  title: string;
  description: string;
  image: string;
  items: IProduct[];
}

const BagsPillowsView: React.FC<Props> = ({
  title,
  description,
  image,
  items,
}) => {
  const [minPrice, setMinPrice] = useState(MIN_PRICE);
  const [maxPrice, setMaxPrice] = useState(MAX_PRICE);
  const [minHeight, setMinHeight] = useState(MIN_HEIGHT);
  const [maxHeight, setMaxHeight] = useState(MAX_HEIGHT);
  const [minWidth, setMinWidth] = useState(MIN_WIDTH);
  const [maxWidth, setMaxWidth] = useState(MAX_WIDTH);

  const filters: FilterSetting[] = [
    {
      title: "Price",
      type: "inRange",
      min: minPrice,
      max: maxPrice,
      minRange: MIN_PRICE,
      maxRange: MAX_PRICE,
      setMin: setMinPrice,
      setMax: setMaxPrice,
    },
    {
      title: "Height",
      type: "inRange",
      min: minHeight,
      max: maxHeight,
      minRange: MIN_HEIGHT,
      maxRange: MAX_HEIGHT,
      setMin: setMinHeight,
      setMax: setMaxHeight,
    },
    {
      title: "Width",
      type: "inRange",
      min: minWidth,
      max: maxWidth,
      minRange: MIN_WIDTH,
      maxRange: MAX_WIDTH,
      setMin: setMinWidth,
      setMax: setMaxWidth,
    },
  ];

  return (
    <ProductsView
      title={title}
      description={description}
      image={image}
      items={items}
      filters={filters}
    />
  );
};

export default BagsPillowsView;
